@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #F5F5F5;
}
nav ul li a:hover,
.active{
  background-color: #E5F0FF;
  color: #1E398F;
  font-weight: 700;
}
/* scroll */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
 
::-webkit-scrollbar-track {
box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: 1px solid slategrey;
}

.grid-full-250{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.background_shadow{
  background-color: rgba(0,0,0,0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 88;
}

/* searchable dropdown */

.searchable {
  display: block !important;
  width: 100% !important;
}
.searchable__controls{
  background-color: #f3f4f6 !important;
  box-shadow: none !important;
}
.white-search .searchable__controls{
  background-color: #fff !important;
  box-shadow: none !important;
}
.searchable__list{
  background-color: white !important;
}

.searchable__list--empty{
  color: rgb(100, 100, 100) !important;
}
.searchable__list__item:hover{
  background-color: white !important;
  color: rgb(2, 2, 113) !important;
}
.searchable--active,
.searchable__list__item,
.searchable__controls__list-input input{
  color: rgb(100, 100, 100) !important;
  border-bottom: none !important;
  border-top: none !important;
  height: 35px !important;
}
.check_in_options .searchable__controls__list-input input{
  height: 20px !important;
}
.searchable__controls__arrow .searchable__controls__arrow__inner svg path{
  stroke: black !important;
  fill: black !important
}
.searchable--active .searchable__controls__arrow__inner svg path{
  stroke: rgb(2, 2, 113) !important;
  fill: rgb(2, 2, 113) !important
}


:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: rgb(100, 100, 100) !important;
}
.searchable__list{
    max-height: 200px !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
    font-size: 14px !important;
}


.searchable__list::-webkit-scrollbar {
  width: 3px;
}
 
.searchable__list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.searchable__list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} 

.searchable__controls__list-input input{
  font-size: 15px !important;
}
.searchable__controls__list-input input::placeholder{
  color: rgb(100, 100, 100) !important;
}


.loader {
  display: block;
  position: absolute;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid var(--orange);
  width: 36px;
  height: 36px;
  left: calc(50% - 18px);
  top: calc(50% - 18px);
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.list li {
  list-style: disc !important;
  margin-left: 20px !important;
}